import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
// import { useQuery } from "react-apollo"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SeoBasic from "../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import BannerHP from "../components/bannerHP"
import getImage from "../functions/getImage"
import shuffle from "../functions/shuffle"
import ReactHtmlParser from "react-html-parser"
// import MailingList from "../components/mailingList"
import ScrollerFooter from "../components/homepage/ScrollerFooter"
import RandomQueryName from "../hooks/random/randomName"
import RandomQueryDate from "../hooks/random/randomDate"
import CaseStudyItemSmall from "../components/caseStudyItemSmall"
import Arrow from "../components/symbols/arrow"
import ScrollerMobile from "../components/homepage/ScrollerMobile"
//allWpCasestudy(limit: 3, sort: {order: DESC, fields: date})

export const query = graphql`
  query{
    wpPage(slug: {eq: "homepage"}) {
    homepage {
      bannerText
      bannerImage {
        mediaDetails {
          sizes {
            sourceUrl
            width
            name
          }
        }
      }
      section1Text
      section1Title
      section2Image {
        mediaDetails {
          sizes {
            name
            sourceUrl
            width
          }
        }
      }
      section2Text
      section3Image {
        mediaDetails {
          sizes {
            name
            sourceUrl
            width
          }
        }
      }
      section3Text
      section4Image {
        mediaDetails {
          sizes {
            name
            sourceUrl
            width
          }
        }
      }
      section4Text
    }
	}
  allWpMediaItem(
    filter: {categories: {nodes: {elemMatch: {name: {eq: "scroller_hompage_footer"}}}}}
    sort: {fields: dateGmt, order: ASC}
  ) {
    edges {
      node {
        id
        title
         mediaItemLink {
          url
        }
        categories {
          nodes {
            id
          }
        }
        mediaItemUrl
        dateGmt
      }
    }
  }
  allWpCasestudy {
    edges {
      node {
        id
        title
        date
        slug
        content
        casestudies {
          images {
            image1{
            mediaDetails {
              sizes {
                name
                sourceUrl
                width
              }
            }
          }
          }
        }
      }
    }
  }
  seoPage:wpPage(slug: {eq: "homepage"}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
  }
`

const HomepageSection1 = ({title, text}) => {

	return (

		<React.Fragment>
		<h1>{title}</h1>

		<div className="body">{text}</div>
		</React.Fragment>

	)

}

const HomepageSection2 = ({img, text}) => {

	return (

    <div>

    <div className="homepage_section2_block">

    </div>

    {img &&
	  <div className="homepage_section2_image" style={{ backgroundImage: `url("${img}")` }}>
	    
		<div>{ReactHtmlParser(text)}</div>

	  </div>
    }	

    </div>

	)

}

const HomepageSection3 = ({img, text}) => {

  return (

    <div>

    <div className="homepage_section3_block">

    </div>

    {img &&
    <div className="homepage_section3_image" style={{ backgroundImage: `url("${img}")` }}>
      
    <div>{ReactHtmlParser(text)}</div>

    </div>  
    }

    </div>

  )

}

const HomepageSection4 = ({img, text}) => {

  return (

    <div>

    <div className="homepage_section4_block">

    </div>

    {img &&
    <div className="homepage_section4_image" style={{ backgroundImage: `url("${img}")` }}>
      
    <div>{ReactHtmlParser(text)}</div>

    </div> 
    } 

    </div>

  )

}

const GetCaseStudies = (items) => {

  // shuffle & limit array to 3
  let shuffledArray = shuffle(items.items.edges)
  let limitedArray = shuffledArray.slice(0,3)
  
  // const images = items?.items?.edges &&
  // items.items.edges.map((item, i) => (
  const images = limitedArray &&
  limitedArray.map((item, i) => (
    
    <CaseStudyItemSmall item={item} i={i} key={i} />
    
  ))

  return images

}

const GetRandomCaseStudies = ({mobile}) => {

  // console.log('////'+mobile)

  const randomNum = Math.round(Math.random()*10)
  // console.log(randomNum)

  if(randomNum>5){
    // console.log('1=====')
    return <RandomQueryName mobile={mobile} />
  }
  else {
    // console.log('2=====')
    return <RandomQueryDate mobile={mobile} />
  }

}

const IndexPage = ({
  data: {
    wpPage: { homepage }, allWpMediaItem, allWpCasestudy, seoPage
  },
}) => {

  const { bannerText } = homepage
  const { section1Text, section1Title } = homepage
  const { section2Text } = homepage
  const { section3Text } = homepage
  const { section4Text } = homepage

  const [loadImageSection, setLoadImageSection] = useState(false)

  useEffect(() => {

      if (typeof window !== "undefined") {
        
        setLoadImageSection(true)
      }
  }, [])

  return(
  <Layout>
    {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title="Homepage" />
    }
    {loadImageSection &&
    <div style={{backgroundColor:"#2F2F2F"}}>

    <div className="header-casestudies-holder">
      <div className="header-casestudies">
        <GetCaseStudies items={allWpCasestudy} />
      </div>

      
      <div className="view-case-studies-holder">
        <Link className="case-studies" to="/casestudies/">
        <div className="view-case-studies">
      
          {/*<div className="header"><h2>View case studies</h2><Arrow /></div>*/}
          <h2>View case studies<Arrow /></h2>
          <p>Learn more about how we build resilient conservation economies to defend our planet's natural capital.</p>

        </div>
        </Link>
      </div>
      
    </div>

    <div className="imageText">
      {homepage.bannerImage &&
    	<BannerHP img={getImage(homepage.bannerImage.mediaDetails.sizes)} text={bannerText} imgclass="bannerImg homepage" />
      }
    </div>

    <div className="homepage_section1">

      <div className="homepage_section1_scroller">

        <ScrollerMobile items={allWpCasestudy.edges} />

      </div>

      
      <div className="view-case-studies-holder">

        <Link className="case-studies" to="/casestudies/">
        <div className="view-case-studies">
      
          <div><h2>View case studies<Arrow /></h2></div>
          <p>Learn more about how we build resilient conservation economies to defend our planet's natural capital.</p>

        </div>
        </Link>
      </div>
      

    	<HomepageSection1 title={section1Title} text={section1Text} />

      {typeof window !== "undefined" && window.innerWidth > 600 && 
      <div className="cs-imagesholder">
        <div className="search-results">
          <GetRandomCaseStudies mobile={false} />
        </div>
      </div>
      }

      {typeof window !== "undefined" && window.innerWidth < 600 && 
      <div className="homepage_section1_scroller bottom">

        <GetRandomCaseStudies mobile={true} />

      </div>
      }

    </div>

    <div className="homepage_section2">
      {homepage.section2Image &&
    	<HomepageSection2 img={getImage(homepage.section2Image.mediaDetails.sizes)} text={section2Text} />
      }
    </div>

    <div className="homepage_section3">
      {homepage.section3Image &&
    	<HomepageSection3 img={getImage(homepage.section3Image.mediaDetails.sizes)} text={section3Text} />
      }
    </div>

    <div className="homepage_section4">
      {homepage.section4Image &&
    	<HomepageSection4 img={getImage(homepage.section4Image.mediaDetails.sizes)} text={section4Text} />
      }
    </div>

  {/*  <div className="mailingList">
      <MailingList styles="mailingList-left" />
    </div>*/}

    <div className="scroller_footer" style={{backgroundColor:`#ffffff`}}>

      <h2>Partners</h2>

      {allWpMediaItem &&
      <ScrollerFooter items={allWpMediaItem.edges} />
      }

    </div>

    </div>
    }
   
  </Layout>
)
}

export default IndexPage
