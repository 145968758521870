import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import { Link } from "gatsby"

const IndexPage = ({img, text, imgclass}) => (

	img &&
  <div className={imgclass} style={{ backgroundImage: `url("${img}")` }}>
    
	<div>{ReactHtmlParser(text)}</div>

	<div className="buttonHolder">

	<Link activeClassName="active" to="/casestudies/"><button className="success" style={{backgroundColor:`#fe912a`, color:`#fff`}}>CASE STUDIES</button></Link>

	<Link activeClassName="active" to="/contact-us/"><button style={{backgroundColor:`transparent`, color:`#fff`, borderColor:`#fe912a`}}>CONTACT US</button></Link>

	</div>

  </div>
)

export default IndexPage
