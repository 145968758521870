export default function shuffle(array){

	 let ctr = array.length;
	
    let temp;
    let index;

    // While there are elements in the array
    while (ctr > 0) {
    	// console.log(ctr)
	// Pick a random index
	        index = Math.floor(Math.random() * ctr);
	// Decrease ctr by 1
	        ctr--;
	// And swap the last element with it
	        temp = array[ctr];
	        array[ctr] = array[index];
	        array[index] = temp;
	    }
    return array;
	
}