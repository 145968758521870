import React, { useEffect, useState } from "react"
import ReactHtmlParser from "react-html-parser"
import getImage from "../functions/getImage"
import Arrow from "../components/symbols/arrow"
import { Link } from "gatsby"

const CaseStudyItemSmall = ({item, i}) => {

	const [loadImageSection, setLoadImageSection] = useState(false)

	useEffect(() => {

	    if (typeof window !== "undefined") {
	      
	      setLoadImageSection(true)
	    }
	}, [])

	if(loadImageSection && item.node.casestudies.images){
	return(

	<Link className="case-studies" to={`/case-studies/${item.node.slug}`} key={i}>
	          
	<div className="item-small">

	 {item.node.casestudies.images.image1 &&
	  <div className="img" style={{ backgroundImage: `url("${getImage(item.node.casestudies.images.image1.mediaDetails.sizes)}")` }}>
	  	 <h2>{item.node.title}<Arrow /></h2>
	  	 {ReactHtmlParser(item.node.content)}

	  </div>
	}

	</div>

	</Link>
	)
	}
	else{

		return null
	}
}

export default CaseStudyItemSmall
